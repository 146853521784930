import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

import { H1, H2, P } from '../shared/text'
import { media } from '../../styles/util'

import TabGroup from '../shared/TabGroup'

import sections from './data'

const Section = styled.section`
  position: relative;
  width: 100%;

  display: grid;
  justify-items: center;
  white-space: pre-line;

  ${media.mobile} {
    padding: 0 30px 77px 30px;
    grid-row-gap: 30px;
    & h2 {
      margin: 16px 0;
      font-size: 25px;
      line-height: 30px;
    }
  }
  ${media.tablet} {
    padding: 0 20px 77px 20px;
    grid-row-gap: 0;
    & h2 {
      margin: 24px 0;
    }
  }

  ${media.desktop} {
    padding: 0 0 77px 0;
  }

  & h1 {
    margin-bottom: 31px;
  }
  & p {
    margin-bottom: 20px;
  }
  & a {
    font-weight: 700;
  }
`

const Gradient = styled.div`
  background: linear-gradient(161.63deg, #99d4ff 8.19%, rgba(255, 255, 255, 0) 67.7%);
  width: 100%;
  height: 600px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`

const Header = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  z-index: 10;

  ${media.mobile} {
    padding: 150px 0 30px 0;
  }
  ${media.tablet} {
    padding: 280px 0 77px 0;
  }
`

const Container = styled.div`
  margin: 0 auto;
  width: 100%;

  ${media.mobile} {
    max-width: 95%;
  }
  ${media.tablet} {
    max-width: 600px;
    padding: 90px 40px 80px 40px;
  }
  ${media.desktop} {
    max-width: 1000px;
  }
`

const GradientWrapper = styled.div`
  padding: 5px;
  background: linear-gradient(
    90deg,
    #fa6666 11.69%,
    #f090c3 28.66%,
    #4bd1c7 62.14%,
    #21a0f8 91.84%
  );
  border-radius: 10px;
`

const BottomBox = styled.div`
  display: grid;
  grid-row-gap: 20px;
  place-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 18px;
  text-align: center;

  & h2,
  p {
    margin: 0;
  }
  & a {
    font-weight: 700;
  }
`

const GreenBlobImg = styled.div`
  position: absolute;
  height: auto;
  z-index: 10;

  ${media.mobile} {
    top: -160px;
    width: 90%;
    left: 8%;
  }
  ${media.tablet} {
    top: -300px;
    width: 700px;
    left: 25%;
  }
`

const PurpleBlobImg = styled(GreenBlobImg)`
  ${media.mobile} {
    top: -30px;
    width: 250px;
    right: -170px;
    left: auto;
  }
  ${media.tablet} {
    top: 0;
    width: 550px;
    right: -300px;
    left: auto;
  }
`

const AlsoViewContainer = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  grid-row-gap: 22px;

  ${media.tablet} {
    display: none;
  }

  & h2 {
    margin: 0 !important;
  }
`

const TabFlexbox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const MobileTabBtn = styled.div`
  width: fit-content;
  min-width: 131px;
  height: 34px;
  border: 1.5px solid ${props => props.theme.colors.purple};
  border-radius: 10px;
  display: grid;
  place-items: center;
  font-family: Cooper, serif;
  margin: 0 8px 15px 8px;

  & svg {
    height: auto;
    width: 140px;
  }
`

const query = graphql`
  query {
    greenBlob: file(relativePath: { regex: "/faq/green-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    purpleBlob: file(relativePath: { regex: "/faq/purple-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const [activeTabIdx, setActiveTabIdx] = useState(0)
  const tabLabels = sections.map(section => section.label)

  const data = useStaticQuery(query)

  useEffect(() => {
    gsap.to('.shipping-and-returns__blob', {
      duration: 'random(4, 6)',
      stagger: 0.25,
      yoyo: true,
      repeat: -1,
      ease: 'ease-in-out',
      y: 'random(24, 32)',
    })
  }, [])

  const unselectedTabs = tabLabels.filter(label => tabLabels.indexOf(label) !== activeTabIdx)

  const handleTabClick = (idx: number) => {
    setActiveTabIdx(idx)
    if (typeof window !== undefined) window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <Section>
      <GreenBlobImg className="shipping-and-returns__blob">
        <Img fluid={data.greenBlob.childImageSharp.fluid} aria-hidden />
      </GreenBlobImg>
      <PurpleBlobImg className="shipping-and-returns__blob">
        <Img fluid={data.purpleBlob.childImageSharp.fluid} aria-hidden />
      </PurpleBlobImg>
      <Gradient />
      <Header>
        <H1>Shipping & returns</H1>
      </Header>
      <TabGroup
        className="shipping-and-returns__tab-group"
        tabs={sections}
        activeTabIdx={activeTabIdx}
        setActiveTabIdx={setActiveTabIdx}
      />
      <Container>
        <H2>{sections[activeTabIdx].subLabel || sections[activeTabIdx].label}</H2>
        {sections[activeTabIdx].copy.map((copy, idx) => (
          <React.Fragment key={`copy-${String(idx)}`}>{copy}</React.Fragment>
        ))}
      </Container>
      <AlsoViewContainer>
        <H2>Also view:</H2>
        <TabFlexbox>
          {unselectedTabs.map(tab => (
            <MobileTabBtn onClick={() => handleTabClick(tabLabels.indexOf(tab))} key={tab}>
              {tab}
            </MobileTabBtn>
          ))}
        </TabFlexbox>
      </AlsoViewContainer>
      <GradientWrapper>
        <BottomBox>
          <H2>{`Have more questions\nor comments?`}</H2>
          <P>
            Contact us at{' '}
            <OutboundLink
              href="mailto:support@eatyourgrummies.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@eatyourgrummies.com
            </OutboundLink>
          </P>
        </BottomBox>
      </GradientWrapper>
    </Section>
  )
}
