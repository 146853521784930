import React from 'react'
import styled, { keyframes } from 'styled-components'
import { gsap } from 'gsap'

import { BasicButton } from './buttons'
import { H2 } from './text'
import { media } from '../../styles/util'

const borderColor = (props: any) => props.theme.colors.purple

const TabGroupContainer = styled.div`
  height: fit-content;
  width: 100%;
  z-index: 100;
  ${media.mobile} {
    padding: 36px 20px 0 20px;
  }
  ${media.desktop} {
    padding: 36px 0 0 0;
    border-bottom: 5px solid ${borderColor};
  }
`

const TabGrid = styled('div')<{ cols: number }>`
  width: fit-content;
  margin: 0 auto;
  grid-template-columns: repeat(${props => props.cols}, auto);
  grid-column-gap: 14px;
  padding: 0 20px;

  ${media.mobile} {
    display: none;
  }
  ${media.desktop} {
    display: grid;
  }
`

const ButtonBorderWrapper = styled('div')<{ active: boolean }>`
  position: relative;
  width: 100%;
  padding-bottom: 21px;
  background-color: ${props => (props.active ? 'white' : 'transparent')};

  border-radius: 18px 18px 0 0;
  border-bottom: 5px solid ${props => (props.active ? 'white' : borderColor)};
  border-top: 5px solid ${props => (props.active ? borderColor : `none`)};
  border-left: ${props => (props.active ? `5px solid ${borderColor(props)}` : 'none')};
  border-right: ${props => (props.active ? `5px solid ${borderColor(props)}` : 'none')};
  margin: ${props => (!props.active ? '5px' : '0')} 0 -5px 0;

  &::before {
    display: ${props => (props.active ? 'block' : 'none')};
    content: '';
    position: absolute;
    bottom: -5px;
    left: -18px;
    height: 18px;
    width: 18px;
    border-bottom-right-radius: 18px;
    border-right: 5px solid ${borderColor};
    border-bottom: 5px solid ${borderColor};
    box-shadow: 5px 5px 0 5px #fff;
  }
  &::after {
    display: ${props => (props.active ? 'block' : 'none')};
    content: '';
    position: absolute;
    bottom: -5px;
    right: -18px;
    height: 18px;
    width: 18px;
    border-bottom-left-radius: 18px;
    border-left: 5px solid ${borderColor};
    border-bottom: 5px solid ${borderColor};
    box-shadow: -5px 5px 0 5px #fff;
  }
`

const Button = styled(BasicButton)<{ active: boolean }>`
  height: 62px;
  border: 2px solid ${props => (props.active ? 'transparent' : props.theme.colors.purple)};
  cursor: ${props => (props.active ? 'default' : 'pointer')};
  transition: 100ms ease-in-out all;
  background-color: white;
  border-radius: ${props => (props.active ? '20px' : '10px')};

  & h2 {
    margin: 0 !important;
  }

  ${media.desktop} {
    padding: 0 30px;

    & h2 {
      font-size: 16px;
    }
  }
  ${media.desktop} {
    padding: 0 45px;

    & h2 {
      font-size: 25px;
    }
  }

  &:hover {
    background-color: ${props => (props.active ? 'transparent' : props.theme.colors.purple)};
    color: ${props => (props.active ? props.theme.colors.purple : 'white')};
    & svg path {
      fill: ${props => (props.active ? props.theme.colors.purple : '#fff')};
    }
  }
`

const fadeInAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`

const AnimationWrapper = styled.div`
  width: 100%;
  animation: ${fadeInAnimation} 1s ease;
`

const SingleTabGroup = styled.div`
  width: 100%;
  height: 44px;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  place-items: center;

  ${media.desktop} {
    display: none;
  }
`

const ActiveMobileTabGradient = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 13px;
  padding: 3.5px;
  background: linear-gradient(90deg, #fa6666 1.9%, #f090c3 28.98%, #52d9bf 64.74%, #21a0f8 100%);
`

const ActiveMobileTab = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  border-radius: 11px;
  background-color: white;

  & h2 {
    font-size: 25px;
    line-height: 1;
    margin: 0 !important;
  }
`

const LeftArrow = styled.div`
  justify-self: start;
`

const RightArrow = styled.div`
  justify-self: end;
`

const TabLabelWrapper = styled('div')`
  & svg {
    height: 26px;
    width: auto;
    margin-bottom: -3px;
  }
`

interface TabLabelInterface {
  label: string
  labelImg?: React.ReactNode
}

interface TabGroupInterface {
  tabs: TabLabelInterface[]
  className: string
  activeTabIdx: number
  setActiveTabIdx: (idx: number) => void
}

const TabGroup: React.FC<TabGroupInterface> = ({
  tabs,
  activeTabIdx,
  setActiveTabIdx,
  className,
  children,
}) => {
  const handleTabChange = (idx: number) => {
    if (activeTabIdx !== idx) {
      const tl = gsap.timeline()
      tl.to(`.${className}--tab-animation`, { opacity: 0, duration: 0.25 })
      tl.call(() => setActiveTabIdx(idx))
      tl.to(`.${className}--tab-animation`, { opacity: 1, duration: 1.25 })
    }
  }

  const handleArrow = (adj: number) => {
    let nextIdx = activeTabIdx + adj
    if (activeTabIdx === 0 && adj === -1) nextIdx = tabs.length - 1
    if (nextIdx === tabs.length) nextIdx = 0
    setActiveTabIdx(nextIdx)
  }

  return (
    <>
      <TabGroupContainer id="section-top">
        <TabGrid cols={tabs.length}>
          {tabs.map((tab, idx) => {
            return (
              <ButtonBorderWrapper active={idx === activeTabIdx} key={tab.label}>
                <Button active={idx === activeTabIdx} onClick={() => handleTabChange(idx)}>
                  {tab.labelImg ? (
                    <TabLabelWrapper>{tab.labelImg}</TabLabelWrapper>
                  ) : (
                    <H2>{tab.label}</H2>
                  )}
                </Button>
              </ButtonBorderWrapper>
            )
          })}
        </TabGrid>
        <SingleTabGroup>
          <LeftArrow onClick={() => handleArrow(-1)}>
            <svg
              width="12"
              height="20"
              viewBox="0 0 12 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.37112e-07 10C4.21444e-07 10.3585 0.144057 10.7169 0.431564 10.9901L9.48337 19.5897C10.0592 20.1368 10.9927 20.1368 11.5683 19.5897C12.1439 19.0429 12.1439 18.1561 11.5683 17.609L3.55878 10L11.568 2.39098C12.1436 1.84394 12.1436 0.957264 11.568 0.410484C10.9925 -0.136826 10.0589 -0.136826 9.48309 0.410484L0.431284 9.00992C0.14373 9.28333 4.52775e-07 9.64173 4.37112e-07 10Z"
                fill="#662D8C"
              />
            </svg>
          </LeftArrow>
          <ActiveMobileTabGradient>
            <ActiveMobileTab>
              {tabs[activeTabIdx].labelImg ? (
                <TabLabelWrapper>{tabs[activeTabIdx].labelImg}</TabLabelWrapper>
              ) : (
                <H2>{tabs[activeTabIdx].label}</H2>
              )}
            </ActiveMobileTab>
          </ActiveMobileTabGradient>
          <RightArrow onClick={() => handleArrow(1)}>
            <svg
              width="12"
              height="20"
              viewBox="0 0 12 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 10C12 10.3585 11.8559 10.7169 11.5684 10.9901L2.51663 19.5897C1.94083 20.1368 1.00725 20.1368 0.43168 19.5897C-0.143894 19.0429 -0.143894 18.1561 0.43168 17.609L8.44122 10L0.431959 2.39098C-0.143615 1.84394 -0.143615 0.957264 0.431959 0.410484C1.00753 -0.136826 1.94111 -0.136826 2.51691 0.410484L11.5687 9.00992C11.8563 9.28333 12 9.64173 12 10Z"
                fill="#662D8C"
              />
            </svg>
          </RightArrow>
        </SingleTabGroup>
      </TabGroupContainer>
      <AnimationWrapper className={`${className}--tab-animation`}>{children}</AnimationWrapper>
    </>
  )
}

export default TabGroup
