import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

import { P } from '../shared/text'

interface FaqDataInterface {
  label: string
  subLabel?: string
  copy: React.ReactNode[]
}

const faqData: FaqDataInterface[] = [
  {
    label: 'Shipping',
    copy: [
      <P>
        Grummies orders come with free shipping for subscriptions and orders $30+. You should
        receive your order in 3-5 business days once it has shipped. However, due to the effects of
        the pandemic on global logistics, there may be delays with transit times. Grummies ships
        within the United States.
      </P>,
      <P>
        We offer standard shipping and charge a small fee if your order is below $30, starting from
        $4. This is dependent on the items in your cart.
      </P>,
      <P>
        If your order tracking says "delivered today" but your package has not yet arrived to your
        mailbox, please wait 24 hours to contact us. UPS and Fedex tracking often show an order as
        "delivered" several hours before it actually is delivered.
      </P>,
    ],
  },
  {
    label: 'Refund policy',
    subLabel: 'Happiness guarantee',
    copy: [
      <P>
        We hope you'll love our products, but in case you don't, we offer a 30-day money-back
        guarantee. Please reach out to us at{' '}
        <OutboundLink
          href="mailto:support@eatyourgrummies.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          support@eatyourgrummies.com
        </OutboundLink>{' '}
        with your Order ID within 30 days of your received order to begin the returns process.
      </P>,
      <P>
        Once initiated, the refund amount will be credited to the original payment method in 7 - 10
        business days.
      </P>,
      <P>Thanks so much!</P>,
    ],
  },
  {
    label: 'Contact us',
    copy: [
      <P>
        We're happy to help! Reach out to us at{' '}
        <OutboundLink
          href="mailto:support@eatyourgrummies.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          support@eatyourgrummies.com
        </OutboundLink>
        .
      </P>,
      <P>
        If your order tracking says "delivered today" but your package has not yet arrived to your
        mailbox, please wait 24 hours to contact us. UPS and Fedex tracking often show an order as
        "delivered" several hours before it actually is delivered.
      </P>,
    ],
  },
]

export default faqData
