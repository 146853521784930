import * as React from 'react'
import { PageProps } from 'gatsby'

import Layout from '../layouts'

import ShippingAndReturns from '../components/ShippingAndReturns'
import SEO from '../components/shared/SEO'

const ShippingPage = (props: PageProps) => {
  const { location } = props
  return (
    <>
      <SEO title="Shipping & Returns" />
      <Layout location={location}>
        <ShippingAndReturns />
      </Layout>
    </>
  )
}

export default ShippingPage
